import {
    Component, 
    ViewChild, 
    TemplateRef, 
    Inject,
    ViewEncapsulation
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';

import { BaseComponent } from "../../base-module/base-component/base.component";

import { ClienteService } from 'app/clientes/services/cliente.service';
import { UsuarioService } from 'app/base-module/base-service/usuario.service';
import { EscritorioService } from 'app/empreendimento/services/escritorio.service';
import { AuthService } from 'app/base-module/base-service/auth.service';
import { ListaAtividadeAssuntoService } from '../services/lista-atividade-assunto.service';
import { UtilitesService } from 'app/base-module/base-service/utilites.service';

@Component({
    selector: 'filtro-atividades',
    templateUrl: './filtro-atividades.component.html',
    styleUrls: ['./filtro-atividades.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [
        ClienteService, 
        EscritorioService
    ]
})

export class FiltroAtividadesComponent extends BaseComponent {

    date = new Date();
    primeiroDia = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
    ultimoDia = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
    
    cliente                 = new FormControl('');
    assessor_id             = new FormControl('');
    escritorio_id           = new FormControl('');
    assunto_id              = new FormControl('');
    atividade_concluidas    = new FormControl('');
    data_operacao_inicio    = new FormControl(this.primeiroDia);
    data_operacao_fim       = new FormControl(this.ultimoDia);

    model:any = {
        escritorio_id: '',
        assessor_id: '',
        assunto_id: '',
        cliente_id: '',
        atividade_concluidas: ''
    }

    loadingSpinner: boolean;
    loadingSpinnerAssunto: boolean = false; 
    loadingSpinnerAssessor:boolean = false;
    loadingSpinnerEscritorio:boolean = false;

    filteredOptions: Observable<string[]>;
    filteredOptionsAtivo: Observable<string[]>;
    listAssessor: any[] = [];
    listEscritorios: any[] = [];
    listAssunto: Object[] = [];
    usuario:any;
    titulo:any;

    @ViewChild('addButtonTmpl', { static: false }) addButtonTmpl: TemplateRef<any>;
    @ViewChild(FiltroAtividadesComponent, { static: false }) table: FiltroAtividadesComponent;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FiltroAtividadesComponent>,
        private _clienteService: ClienteService,        
        private usuarioService: UsuarioService,
        private _authService: AuthService,
        public _escritorioService: EscritorioService,
        private _utilitesService: UtilitesService,
        public _assuntoAtividadeService: ListaAtividadeAssuntoService) {

        super(dialog, snackBar);

        this.usuario = JSON.parse(this._authService.getUser());

        this.cliente.valueChanges.subscribe(
            query => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            }
        ); 
        
        this.getAllEscritorios();
        this.getAssessores();
        this.getAllAssuntos();

        this.titulo = "Filtrar Atividades";
    }

    displayFn(cliente): void {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente): void{
        this.loadingSpinner  = false;
    }

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    selectEscritorio(event){
        this.escritorio_id.setValue(event.value);
        this.getAssessores(event.value);
    }

    filtrar(): void {

        var inic = null;
        if (this.data_operacao_inicio.value != null){
            inic = this._utilitesService.formatDateToString(new Date(this.data_operacao_inicio.value));
        }

        var fim = null;
        if (this.data_operacao_fim.value != null) {
            fim = this._utilitesService.formatDateToString(new Date(this.data_operacao_fim.value));
        }
        
        this.dialogRef.close({
            cliente: this.cliente.value,
            escritorio_id: this.escritorio_id.value,
            assessor_id: this.assessor_id.value,
            assunto_id: this.assunto_id.value,
            data_operacao_inicio: inic,
            data_operacao_fim: fim,
            atividade_concluidas: this.atividade_concluidas.value,
        });
    }

    getAssessores(escritorio_id = '') {
        this.loadingSpinnerAssessor = true;
        this.usuarioService.getLista({escritorio_id:escritorio_id})
            .subscribe(
                resquest => {
                    this.listAssessor = resquest.data;
                },
                error => {
                    this.loadingSpinnerAssessor = false;
                },
                () => {
                    this.loadingSpinnerAssessor = false;
                    console.log("complete");
                }
            );
    }
    
    getAllEscritorios() {
        this.loadingSpinnerEscritorio = true;
        this._escritorioService.getAllList({no_paginate:true}).subscribe(
            list => {        
                this.listEscritorios = list.data;
            },
            error => {
                this.loadingSpinnerEscritorio = false;
            },
            () => {
                this.loadingSpinnerEscritorio = false;
            });
    }

    getAllAssuntos() {
        this.loadingSpinnerAssunto = true;
        this._assuntoAtividadeService.getLista({is_subject_prosperidade: true}).subscribe(
            list => {
                this.listAssunto = list.data;
            },
            error => {
                this.loadingSpinnerAssunto = false;
            },
            () => {
                this.loadingSpinnerAssunto = false;
            });
    }

}