import {
    Component,
    Inject,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
    MatSnackBar
} from '@angular/material';

import { FormControl, Validators } from '@angular/forms';

import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';

import { ActionDialog, BaseComponent, LoadingTeste } from "../../base-module/base-component/base.component";

import { TipoOportunidadeService } from 'app/auxiliares/services/tipo-oportunidade-service';
import { IItemsPermissionActions } from 'app/interfaces/IItemsPermissionActions';
import { ListaAtividadeAssuntoService } from 'app/lista-atividades/services/lista-atividade-assunto.service';
import { ListaAtividadeService } from 'app/lista-atividades/services/lista-atividades.service';
import { TarefasRespostaUsuarioService } from '../../lista-atividades/services/lista-atividades-tarefas-respostas.service';
import { AcaoService } from '../../auxiliares/services/acao-service';
import { AuthService } from "../../base-module/base-service/auth.service";
import { ClienteOportunidadeService } from '../services/cliente-oportunidade.service';
import { ClienteRelacionentoService } from '../services/cliente-relacionamento.service';
import { ClienteService } from '../services/cliente.service';

export interface DialogData {
    valor: any;
    valor_surgerido: any;
    id: number,
    cliente_id: number;
    dados_cliente:object;
    only_show: boolean;
    atividade_cliente_id: number;
    user_role_id: number;
    is_especialista: boolean;
    user_id: number;
}

interface Actions {
    buttonAlterOportunit: IItemsPermissionActions;
    buttonAddOportunit: IItemsPermissionActions;
}

@Component({
    selector: "app-cadastro-relacionamento-cliente",
    templateUrl: "./cadastro-relacionamento-cliente.component.html",
    styleUrls: ["./cadastro-relacionamento-cliente.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [AcaoService],
})
export class CadastroRelacionamentoClienteComponent extends BaseComponent implements OnInit {
    observacao = new FormControl("", [Validators.required]);
    resposta = new FormControl("", [Validators.required]);
    acao_id = new FormControl("", [Validators.required]);
    cliente = new FormControl("");
    assunto_follow_up = new FormControl("", [Validators.required]);
    data_follow_up = new FormControl("");
    assunto_id = new FormControl("");

    descricao = new FormControl("");
    data_retorno = new FormControl("");
    valor = new FormControl('');
    valor_sugerido = new FormControl('');
    tipo_oportunidade_id = new FormControl("");
    isVisibleValor = false;

    model_tarefa_resposta: any = {
        "resposta": "",
        "valor": 0.00,
        "tarefa_id": this.data.atividade_cliente_id,
        "cliente_aceitou": false,
        "user_id": this.data.user_id
    }

    modelUpdate: any = {
        id: "",
        resposta: "",
        clienteAceitou: false,
        valor: 0.00
    }

    model: any = {
        cliente_id: "",
        assunto_id: "",
        acao_id: "",
        acao_retorno_id: "",
        data_follow_up: "",
        observacao: "",
        resposta: "",
    };

    model_tarefa: any = {
        cliente_id: "",
        observacao: "",
        assunto_id: "",
        data_hora_atividade: "",
        tarefa_id: "",
        valor_sugerido: 0.00
    };

    loadingLocal: any;
    loadingSpinnerAcao: boolean = false;
    loadingSpinnerAssunto: boolean = false;
    loadingSpinner: boolean = false;
    loadingSpinnerTipo: boolean = false;
    loadingIndicatorOportunidade: boolean = false;
    isVisibleAgendamentoFuturo: boolean = false;
    isVisibleOportunidade: boolean = false;
    listAcao: any[] = [];
    listAtividade: any[] = [];
    listTipo: any[] = [];
    filteredOptions: Observable<string[]>;
    showClientField:boolean = false;
    todayDate: Date = new Date();
    isOportunidade = false;
    isOportunidadeSend = false;
    clienteAceitou = false;

    @ViewChild("addButtonTmpl", { static: false })
    addButtonTmpl: TemplateRef<any>;
    @ViewChild(CadastroRelacionamentoClienteComponent, { static: false })
    table: CadastroRelacionamentoClienteComponent;

    _actions: Actions = {
        buttonAlterOportunit: {
            permission: 'Editar Oportunidade',
            enabled: false
        },
        buttonAddOportunit: {
            permission: 'Cadastrar Oportunidade',
            enabled: false
        },
    }

    constructor(
        public service: ClienteRelacionentoService,
        public _tipoOportunidadeService: TipoOportunidadeService,
        public _serviceOportunidade: ClienteOportunidadeService,
        private _clienteService: ClienteService,
        private _listaAtividadeAssuntoService: ListaAtividadeAssuntoService,
        private _listaAtividade: ListaAtividadeService, 
        private _tarefasRespotaUsuario: TarefasRespostaUsuarioService, 
        private _acaoService: AcaoService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private loading: MatDialog,
        public authService: AuthService,
        public dialogRef: MatDialogRef<CadastroRelacionamentoClienteComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        super(dialog, snackBar);

        if (this.data.id != null && this.data.id != undefined) {
            this.getById();
            // this.getAllOportunidades();
            this.isVisibleOportunidade = true;
        }
        
        this.listAcao = [];
        this.listAtividade = [];
        this.getAllAcao();
        // this.getAllTipoOportunidade();       
        this.getAllAssuntoListaAtividades();       
    }

    ngOnInit(): void {                        
        this.verifyPermissionForActions();
        
        if(this.data.cliente_id == null || this.data.cliente_id == undefined) {
            this.showClientField = true;
            this.cliente.valueChanges.subscribe((query) => {
                if (typeof query === "string" || typeof query === "number") {
                    this.loadingSpinner = true;
                    this.params.search = query;
                    this.filteredOptions = this._clienteService.search(this.params);
                }
            });
        }else{
            this.showClientField = false;
        }
    }

    displayFn(cliente) {
        if (cliente) {
            return cliente.nome; 
        }
    }

    getCliente(cliente){
        this.loadingSpinner  = false;
    }   

    onNoClick(): void {
        this.dialogRef.close("cancel");
    }

    /**
     * Abre loagind
     */
    openLoadingLocal(): void {
        this.loadingLocal = this.loading.open(LoadingTeste, {
            width: "150px",
            disableClose: true,
        });
    }

    /**
     * Fecha loagind
     */
    closeLoadingLocal(): void {
        if (this.loadingLocal != null) {
            this.loadingLocal.close();
        }
    }

    addOportunidade(){
        this.model_tarefa.cliente_id = this.data.cliente_id;
        this.model_tarefa.observacao = this.observacao.value;
        this.model_tarefa.assunto_id = this.assunto_id.value;
        this.model_tarefa.data_hora_atividade = this.data_follow_up.value;
        this.model_tarefa.tarefa_id = this.data.atividade_cliente_id
        if(this.valor_sugerido.value) this.model_tarefa.valor_sugerido = this.valor_sugerido.value;

        if(this.model_tarefa.cliente_id && this.model_tarefa.observacao && 
            this.model_tarefa.assunto_id && this.model_tarefa.data_hora_atividade && this.model_tarefa.tarefa_id
        ) {
            this._listaAtividade.create(this.model_tarefa).subscribe(
                (data) => {                    
                    this.getAllOportunidades();                  
                },
                (error) => {
                    this.showError(error.error.message);                    
                },
            );
        }
        this.data_follow_up.reset();
        this.data_follow_up.clearValidators();
        this.assunto_id.reset();
        this.assunto_id.clearAsyncValidators();
        this.observacao.reset();
        this.observacao.clearValidators();
        this.valor.setValue(0);

        this.isOportunidadeSend = true;
    }

    deleteOportunidade(id) {
        var lista = this;
       
        this.dialog.open(ActionDialog, {
            width: "250px",
            disableClose: true,
            panelClass: "modal-delete",
            data: {
                title: "Excluir Oportunidade",
                text: "Deseja realmente excluir este item?",
                callback: function () {    
                    lista.loadingIndicatorOportunidade = true;                
                    lista._listaAtividade.delete(id).subscribe(
                        (data) => {
                            lista.showSuccess(data.message);
                            lista.getAllOportunidades();
                        },
                        (error) => {                            
                            lista.showError(error.error.message);
                        },
                        () => {
                            lista.loadingIndicatorOportunidade = false;
                        }
                    );
                },
            },
        });
    }


    isInvalidForm() {
        if (!this.resposta.valid) {
            return true;
        }

        return false;
    }

    canCancel() {
        if (this.isOportunidadeSend) return true;
        return false;
    }
    
    canCancelFutureContact() {
        if (this.isOportunidadeSend) return true;
        if (this.data.is_especialista) return true;
        return false;
    }

    onToggleChange() {
        this.valor.setValue(0); // Reseta o valor para 0 quando o usuário alterna entre os botões
    }

    salvar(verOportunidade) {
        this.openLoading();
        
        if (this.data.is_especialista) {
            this.model_tarefa_resposta.resposta = this.resposta.value;
            this.model_tarefa_resposta.cliente_aceitou = this.clienteAceitou;
            if(this.valor.value) this.model_tarefa_resposta.valor = this.valor.value;


            this._tarefasRespotaUsuario.create(this.model_tarefa_resposta).subscribe(
                (data) => {
                    this.closeLoading();
                    this.dialogRef.close(data);
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);                    
                },
                () => {
                    this.closeLoading();
                }
            );
        }
        else {
            this.modelUpdate.id = this.data.atividade_cliente_id;
            this.modelUpdate.resposta = this.resposta.value;
            this.modelUpdate.cliente_aceitou = this.clienteAceitou;     
            if(this.valor.value) this.modelUpdate.valor = this.valor.value;
            
            this._listaAtividade.update(this.modelUpdate.id, this.modelUpdate).subscribe(
                (data) => {
                    // this.data.id = data.data.id;
                    this.closeLoading();
                    this.getById();
                    // this.getAllTipoOportunidade();
    
                    if(verOportunidade){
                        this.isVisibleOportunidade = true;
                    }else{
                        this.dialogRef.close(data);
                        this.isVisibleOportunidade = false;
                    }
                },
                (error) => {
                    this.closeLoading();
                    this.showError(error.error.message);                    
                },
                () => {
                    this.closeLoading();
                }
            );
        }
    }

    getErrorObservacaoMessage() {
        return this.observacao.hasError("required")
            ? "Observação é um campo obrigatório"
            : "";
    }
    getErrorRespostaMessage() {
        return this.resposta.hasError("required")
            ? "Resposta é um campo obrigatório"
            : "";
    }
    getErrorValorMessage() {
        return this.resposta.hasError("required")
            ? "Valor é um campo obrigatório"
            : "";
    }

    getErrorAcaoMessage() {
        return this.acao_id.hasError("required")
            ? "Ação é um campo obrigatório"
            : "";
    }

    toggleOportunidade() {
        if (!this.isOportunidade) {
          this.valor.reset(); // Zera o campo Valor
          this.valor.clearValidators(); // Remove a obrigatoriedade
        } else {
          this.valor.setValidators(Validators.required); // Torna o campo obrigatório em Oportunidade
        }
        this.valor.updateValueAndValidity(); // Atualiza a validação
    }


    isInvalidFormOportunidade() {
        if (!this.assunto_id){
            return true;
        }
        if (!this.observacao.valid) {            
            return true;
        }
        
        if (!this.tipo_oportunidade_id.valid) {
            return true;
        }
        
        if (!this.data_follow_up.valid) {
            return true;
        }

        return false;
    }

    getById() {
        this.openLoading();
        this.service.getById(this.data.id).subscribe(
            (item) => {
                this.model = item.data;
                this.acao_id.setValue(item.data.acao_id);
                this.assunto_id.setValue(item.data.assunto_id);
                this.observacao.setValue(item.data.observacao);
                this.cliente.setValue(item.data.cliente);
                this.data_follow_up.setValue(item.data.data_follow_up);
            },
            (error) => {
                this.closeLoading();
            },
            () => {
                this.closeLoading();
            }
        );
    }

    getAllAcao() {
        this.loadingSpinnerAcao = true;
        this._acaoService.getAll({}).subscribe(
            (list) => {
                this.listAcao = list.data;
            },
            (error) => {},
            () => {
                this.loadingSpinnerAcao = false;
            }
        );
    }

    getAllOportunidades() {
        this.loadingIndicatorOportunidade = true;
        
        this.params.tarefa_id = this.data.atividade_cliente_id;
        
        this._listaAtividade.getAll(this.params).subscribe(
            (data) => {
                this.length = data.meta.total;
                this.rows = data.data;
                this.temp = [...this.rows];
            },
            (error) => {               
                this.loadingIndicatorOportunidade = false;
            },
            () => {             
                this.loadingIndicatorOportunidade = false;
            }
        );
    }

    getAllAssuntoListaAtividades() {
        this.loadingSpinnerAssunto = true;
        this._listaAtividadeAssuntoService.getLista({is_subject_prosperidade: false}).subscribe(
            (list) => {
                this.listAtividade = list.data;
            },
            (error) => {},
            () => {
                this.loadingSpinnerAssunto = false;
            }
        );
    }

    alternarCampoValor() {
        this.isVisibleValor = !this.isVisibleValor;
    
        if (this.isVisibleValor) {
          // Torna o campo obrigatório
          this.valor.setValidators([Validators.required]);
        } else {
          // Remove o valor e a validação de obrigatoriedade
          this.valor.reset();
          this.valor.clearValidators();
        }
    
        // Atualiza o estado do controle de formulário para aplicar as mudanças
        this.valor.updateValueAndValidity();
    }

    verifyPermissionForActions(){
        this._actions.buttonAlterOportunit.enabled = this.authService
        .isPermissao([this._actions.buttonAlterOportunit.permission]);
        this._actions.buttonAddOportunit.enabled = this.authService
        .isPermissao([this._actions.buttonAddOportunit.permission]);
    }

    addContatoFuturo(){
        this.isVisibleAgendamentoFuturo = !this.isVisibleAgendamentoFuturo;
        if (this.isVisibleAgendamentoFuturo) {
            // Torna o campo obrigatório
            this.data_follow_up.setValidators([Validators.required]);
            this.assunto_id.setValidators([Validators.required]);
            this.observacao.setValidators([Validators.required]);
        } else {
            // Remove o valor e a validação de obrigatoriedade
            this.data_follow_up.reset();
            this.data_follow_up.clearValidators();
            this.assunto_id.reset();
            this.assunto_id.clearAsyncValidators();
            this.observacao.reset();
            this.observacao.clearValidators();
            this.valor.reset();
            this.valor.clearValidators();
        }
        
        // Atualiza o estado do controle de formulário para aplicar as mudanças
        this.data_follow_up.updateValueAndValidity();
        this.assunto_id.updateValueAndValidity();
        this.observacao.updateValueAndValidity();
          this.valor.updateValueAndValidity();
        
        if (!this.isVisibleAgendamentoFuturo) {
            this.data_follow_up.setValue("");
            this.assunto_id.setValue("");
            this.observacao.setValue("");
        }
    }
    formatarValor(event: any) {
        // Previne a recursão infinita ao alterar o valor
        const valorAtual = this.valor.value;
    
        // Remove 'R$' e formata o valor
        const novoValor = event.target.value;
    
        // Atualiza o valor apenas se for diferente do atual
        if (novoValor !== valorAtual) {
            this.valor.setValue(novoValor);
        }
    }
}
