import {
    Component, 
    ViewEncapsulation,
    Inject
} from '@angular/core';

import {
    MatDialog,
    MatSnackBar,
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material';

import { fuseAnimations } from '@fuse/animations';

import { BaseComponent } from "../../base-module/base-component/base.component";
import { TarefasRespostaUsuarioService } from "../services/lista-atividades-tarefas-respostas.service";

export interface DialogData {
    tarefas_respostas_usuario: Array<any>;
}

@Component({
    selector: 'resposta-tarefa-especialista',
    templateUrl: './resposta-tarefa-especialista.component.html',
    styleUrls: ['./resposta-tarefa-especialista.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class RespostaTarefaEspecialistaComponent extends BaseComponent {
    rows: any;
    is_tarefas_empty: boolean = false;
    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private _tarefasRespotaUsuario: TarefasRespostaUsuarioService, 
        public dialogRef: MatDialogRef<RespostaTarefaEspecialistaComponent>,
        @Inject(MAT_DIALOG_DATA) public row: DialogData
    ) {
        super(dialog, snackBar);      
    }
    
    ngOnInit(): void {
        this.rows = this.row.tarefas_respostas_usuario;
    }


    async getAll(row) {
        return new Promise((resolve, reject) => {
            this._tarefasRespotaUsuario.getAll({ tarefa_id: row.tarefa_id }).subscribe(
                (data) => {
                    this.rows = data.data; // Atualiza this.rows
                    console.log('Valor de this.rows:', this.rows);
                    resolve(data);
                },
                (error) => {
                    this.showError(error.error.message);
                    reject(error);
                },
                () => {
                    this.closeLoading();
                }
            );
        });
    }
    
    async excluirItem(row) {
        this.openLoading();
        try {
            await this._tarefasRespotaUsuario.update(row.id, { id: row.id, deleted: true }).toPromise(); // Converte para Promise
            await this.getAll(row);
            this.closeLoading();
    
            if (Array.isArray(this.rows) && this.rows.length === 0) {
                console.log('Fechando o modal, rows está vazio.');
                this.dialogRef.close();
            }
        } catch (error) {
            this.closeLoading();
            this.showError(error.error.message);
        }
    }
    
}
