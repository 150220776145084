import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatButtonToggleModule
} from '@angular/material';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChartsModule } from 'ng2-charts';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { NgxMaskModule } from "ngx-mask";
import { MatBadgeModule } from '@angular/material/badge';

//Modules
import { ClientesModule } from 'app/clientes/clientes.module';
import { BaseModule } from "../base-module/base.module";
import { ListaAtividadesRoutesModule } from './lista-atividades.routing';

//Componentes
import { CadastraAtividadeClientesComponent } from './cadastra-atividade-clientes/cadastra-atividade-clientes.component';
import { CadastroAssuntoComponent } from './cadastro-assunto/cadastro-assunto.component';
import { FiltroAtividadesComponent } from './filtro-atividades/filtro-atividades.component';
import { ListaAssuntoComponent } from './lista-assunto/lista-assunto.component';
import { ListaTodasAtividadesComponent } from './lista-todas-atividades/lista-todas-atividades.component';
import { ModalShowAtividadeComponent } from './modal-show-atividade/modal-show-atividade.component';
import { ResponderAtividadeComponent } from './responder-atividade/responder-atividade.component';
import { RespostaAtividadeComponent } from './resposta-atividade/resposta-atividade.component';
import { RespostaTarefaEspecialistaComponent } from './resposta-tarefa-especialista/resposta-tarefa-especialista.component';

//Services
import { ModalEscolhaAcaoComponent } from './modal-escolha-acao/modal-escolha-acao.component';
import { ListaAtividadeAssuntoService } from './services/lista-atividade-assunto.service';
import { TarefasRespostaUsuarioService } from './services/lista-atividades-tarefas-respostas.service';
import { ListaAtividadeClienteService } from './services/lista-atividade-clientes.service';
import { ListaAtividadeService } from './services/lista-atividades.service';

@NgModule({  
    imports: [
        CommonModule,        
        ListaAtividadesRoutesModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatGridListModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatListModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatTabsModule,
        MatRadioModule, 
        MatMenuModule, 
        MatChipsModule, 
        MatExpansionModule, 
        MatFormFieldModule, 
        MatRippleModule, 
        MatSortModule, 
        MatTableModule,
        FormsModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        FlexLayoutModule,
        BaseModule,
        MatNativeDateModule,
        NgxMaskModule.forRoot(),
        CurrencyMaskModule,
        ClientesModule,
        FuseWidgetModule,
        NgxChartsModule,
        ChartsModule,
        MatBadgeModule
    ],
    declarations: [
        CadastroAssuntoComponent,
        ListaAssuntoComponent,
        CadastraAtividadeClientesComponent,
        ModalShowAtividadeComponent,
        ListaTodasAtividadesComponent,
        FiltroAtividadesComponent,
        RespostaAtividadeComponent,
        RespostaTarefaEspecialistaComponent,
        ResponderAtividadeComponent,
        ModalEscolhaAcaoComponent
    ],
    entryComponents: [
        CadastraAtividadeClientesComponent,
        CadastroAssuntoComponent,
        ModalShowAtividadeComponent,
        FiltroAtividadesComponent,
        RespostaAtividadeComponent,
        RespostaTarefaEspecialistaComponent,
        ResponderAtividadeComponent,
        ModalEscolhaAcaoComponent
    ],
    providers: [
       ListaAtividadeAssuntoService,
       ListaAtividadeService,
       ListaAtividadeClienteService,
       TarefasRespostaUsuarioService
    ]
})
export class ListaAtividadesModule { }
