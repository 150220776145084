import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatGridListModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatListModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMenuModule,
    MatChipsModule,
    MatExpansionModule,
    MatTableModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatButtonToggleModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {AuthService} from '../base-module/base-service/auth.service';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import {NgxMaskModule} from "ngx-mask";
import {BaseModule} from "../base-module/base.module";
import { ClientesRoutesModule } from "./clientes.routing";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

/** Components */
import {ListaComponent} from "./lista/lista.component";
import {CadastroCompletoComponent} from "./cadastro-completo/cadastro-completo.component";
import { FiltrarClienteComponent } from './filtrar-cliente/filtrar-cliente.component';
import { CadastroRelacionamentoComponent } from './cadastro-relacionamento/cadastro-relacionamento.component';
import { FiltrarRelacionamentoComponent } from './filtrar-relacionamento/filtrar-relacionamento.component';
import { AlteraCarteiraComponent } from './altera-carteira/altera-carteira.component';
import { ListaSaldoClienteComponent } from './lista-saldo-cliente/lista-saldo-cliente.component';
import { CadastraSaldoClienteComponent } from './cadastra-saldo-cliente/cadastra-saldo-cliente.component';
import { FeedbackClienteComponent } from './cliente-dados/feedback-cliente/feedback-cliente.component';
import { PosicaoConsolidadaComponent } from './cliente-dados/posicao-consolidada/posicao-consolidada.component';
import { CadastroAporteRetiradaComponent } from './cadastro-aporte-retirada/cadastro-aporte-retirada.component';
import { ListaStatusOperacaoComponent } from './lista-status-operacao/lista-status-operacao.component';
import { ListaClientesSemOrdemComponent } from './lista-clientes-sem-ordem/lista-clientes-sem-ordem.component';
import { ListaHistoricoClienteComponent } from './cliente-dados/lista-historico-cliente/lista-historico-cliente.component';
import { CadastroHistoricoClienteComponent } from './cadastro-historico-cliente/cadastro-historico-cliente.component';
import { FiltroHistoricoClienteComponent } from './filtro-historico-cliente/filtro-historico-cliente.component';
import { ListaCustodiaRvClienteComponent } from './custodia-rv-cliente/lista-custodia-rv-cliente/lista-custodia-rv-cliente.component';
import { CadastroCompletoPainelComponent } from './cliente-dados/cadastro-completo-painel/cadastro-completo-painel.component';
import { UploadHistoricoClienteComponent } from './upload-historico-cliente/upload-historico-cliente.component';
import { ListaIconsistenciaClienteComponent } from './lista-iconsistencia-cliente/lista-iconsistencia-cliente.component';
import { FiltrarInconsistenciasOrdensComponent } from './filtrar-inconsistencias-ordens/filtrar-inconsistencias-ordens.component';
import { ListaHistoricoRelacionamentoComponent } from './lista-historico-relacionamento/lista-historico-relacionamento.component';
import { CadastroClienteSimplesComponent } from './cadastro-cliente-simples/cadastro-cliente-simples.component';
import { ClienteRendaVariavelComponent } from './produtos/cliente-renda-variavel/cliente-renda-variavel.component';
import { ClienteProdutosComponent } from './cliente-produtos/cliente-produtos.component';
import { CoeComponent } from './produtos/coe/coe.component';
import { ClienteRendaFixaComponent } from './produtos/cliente-renda-fixa/cliente-renda-fixa.component';
import { CadastroProdutoComponent } from './produtos/cadastro-produto/cadastro-produto.component';
import { ClientePrevidenciaComponent } from './produtos/cliente-previdencia/cliente-previdencia.component';
import { ClienteFundoInvestimentoComponent } from './produtos/cliente-fundo-investimento/cliente-fundo-investimento.component';
import { TimeLineClienteComponent } from './time-line-cliente/time-line-cliente.component';
import { CadastroEstruturaFamiliarComponent } from 'app/clientes/cliente-dados/estrutura-familiar/cadastro-estrutura-familiar/cadastro-estrutura-familiar.component';
import { ListaEstruturaFamiliarComponent } from 'app/clientes/cliente-dados/estrutura-familiar/lista-estrutura-familiar/lista-estrutura-familiar.component';

/** Services */
import {ClienteService} from "./services/cliente.service";
import {EnderecoService} from "../base-module/base-service/endereco.service";
import {EmpresaService} from "../empresas/services/empresa.service";
import {ClienteRelacionentoService} from './services/cliente-relacionamento.service';
import { CarteiraService } from './services/carteira.service';
import { StatusOperacaoService } from './services/status-operacao.service';
import { ClientePerfilService } from './services/cliente-perfil.service';
import { FeedbackService } from './services/feedback.service';
import { SaldoClientesService } from './services/saldo-clientes.service';
import { ClienteHistoricoService } from './services/cliente-historico.service';
import { EstadoCivilService } from './services/estado-civil.service';
import { ClienteFundoInvestimentoService } from './services/cliente-fundo-investimento.service';
import { SituacaoTributariaService } from 'app/auxiliares/services/situacao-tributaria.service';
import { PrimeiroContatoService } from 'app/auxiliares/services/primeiro-contato.service';
import { ClienteCarteiraHistoricoService } from './services/cliente-carteira-historico.service';
import { ProfissaoService } from 'app/auxiliares/services/profissao.service';
import { OrigemService } from 'app/auxiliares/services/origem-cliente.service';
import { TarefasRespostaUsuarioService } from '../lista-atividades/services/lista-atividades-tarefas-respostas.service';

import { VerticalTimelineModule } from 'angular-vertical-timeline';
import { MovimentacaoModule } from 'app/movimentacao/movimentacao.module';
import { RendaVariavelModule } from 'app/renda-variavel/renda-variavel.module';
import { RendaFixaModule } from 'app/renda-fixa/renda-fixa.module';
import { PrevidenciaModule } from 'app/previdencia/previdencia.module';
import { ConsorcioModule } from 'app/consorcio/consorcio.module';
import { ListaEstrategiaCustodiaComponent } from './custodia-rv-cliente/lista-estrategia-custodia/lista-estrategia-custodia.component';
import { ListaCustodiaDerivativosComponent } from './custodia-rv-cliente/lista-custodia-derivativos/lista-custodia-derivativos.component';
import { ListaCustodiaProventosComponent } from './custodia-rv-cliente/lista-custodia-proventos/lista-custodia-proventos.component';
import { InvestimentosComponent } from './cliente-dados/investimentos/investimentos.component';
import { ClienteRendaVariavelPerfilComponent } from './cliente-dados/cliente-renda-variavel-perfil/cliente-renda-variavel-perfil.component';
import { CadastroDadosFinanceiroComponent } from './cliente-dados/cadastro-dados-financeiro/cadastro-dados-financeiro.component';
import { EvolucaoPatrimonialComponent } from './cliente-dados/evolucao-patrimonial/evolucao-patrimonial.component';
import { HistoricoMovimentacaoComponent } from './cliente-dados/historico-movimentacao/historico-movimentacao.component';
import { ModalDetailNnmComponent } from './cliente-dados/modal-detail-nnm/modal-detail-nnm.component';
import { NgxHorizontalTimelineModule } from "ngx-horizontal-timeline";
import { ModalRelacionamentoComponent } from './modal-relacionamento/modal-relacionamento.component';
import { CadastroRelacionamentoClienteComponent } from './cadastro-relacionamento-cliente/cadastro-relacionamento-cliente.component';
import { GestaoRelacionamentoClienteComponent } from "./gestao-relacionamento-cliente/gestao-relacionamento-cliente.component";
import { ListaOportunidadeClienteComponent } from './lista-oportunidade-cliente/lista-oportunidade-cliente.component';
import { TipoOportunidadeService } from "../auxiliares/services/tipo-oportunidade-service";
import { ClienteOportunidadeService } from "./services/cliente-oportunidade.service";
import { CadastroOportunidadeComponent } from './cadastro-oportunidade/cadastro-oportunidade.component';
import { ListaLimitadaComponent } from './lista-limita/lista-limitada.component';
import { CadastroCompletoStepperComponent } from './cadastro-completo-stepper/cadastro-completo-stepper.component';
import { TipoCustoService } from './services/tipo-custo.service';
import { ListaTipoCustoComponent } from './lista-tipo-custo/lista-tipo-custo.component';
import { CadastroTipoCustoComponent } from './cadastro-tipo-custo/cadastro-tipo-custo.component';
import { TipoReceitaService } from './services/tipo-receita.service';
import { TipoPatrimonioService } from './services/tipo-patrimonio.service';
import { ListaTipoReceitaComponent } from './lista-tipo-receita/lista-tipo-receita.component';
import { CadastroTipoReceitaComponent } from './cadastro-tipo-receita/cadastro-tipo-receita.component';
import { ListaTipoPatrimonioComponent } from './lista-tipo-patrimonio/lista-tipo-patrimonio.component';
import { CadastroTipoPatrimonioComponent } from './cadastro-tipo-patrimonio/cadastro-tipo-patrimonio.component';
import { ClienteCustoService } from './services/cliente-custo.service';
import { CadastroClienteCustosComponent } from './cadastro-cliente-custos/cadastro-cliente-custos.component';
import { ListaClienteCustoComponent } from './lista-cliente-custo/lista-cliente-custo.component';
import { ClienteReceitaService } from './services/cliente-receita.service';
import { ListaClienteReceitaComponent } from './lista-cliente-receita/lista-cliente-receita.component';
import { CadastroClienteReceitasComponent } from './cadastro-cliente-receitas/cadastro-cliente-receitas.component';
import { ListaClientePatrimonioComponent } from './lista-cliente-patrimonio/lista-cliente-patrimonio.component';
import { CadastroClientePatrimoniosComponent } from './cadastro-cliente-patrimonio/cadastro-cliente-patrimonio.component';
import { ClientePatrimonioService } from './services/cliente-patrimonio.service';
import { ClienteProjetoService } from './services/cliente-projeto.service';
import { CadastroClienteProjetosComponent } from './cadastro-cliente-projeto/cadastro-cliente-projeto.component';
import { ClienteIndicacaoService } from './services/cliente-indicacao.service';
import { ListaClienteProjetoComponent } from './lista-cliente-projeto/lista-cliente-projeto.component';
import { ListaClienteIndicacaoComponent } from './lista-cliente-indicacao/lista-cliente-indicacao.component';
import { CadastroClienteIndicacaoComponent } from './cadastro-cliente-indicacao/cadastro-cliente-indicacao.component';
import { ListaGestaoRelacionamentoComponent } from './lista-gestao-relacionamento/lista-gestao-relacionamento.component';
import { FiltrarGestaoRelacionamentoComponent } from './filtrar-gestao-relacionamento/filtrar-gestao-relacionamento.component';

@NgModule({
    imports: [
        CommonModule,
        ClientesRoutesModule,
        RendaFixaModule,
        PrevidenciaModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        MatGridListModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatPaginatorModule,
        MatListModule,
        MatAutocompleteModule,
        BaseModule,
        FileUploadModule,
        MatSelectModule,
        MatOptionModule,
        NgxMaskModule,
        MatTabsModule,
        MatRadioModule,
        MatDatepickerModule,
        MatSelectModule,
        CurrencyMaskModule,
        MatMenuModule,
        MatChipsModule,
        NgxChartsModule,
        ChartsModule,
        FuseWidgetModule,
        DragDropModule,
        MatExpansionModule,
        VerticalTimelineModule,
        MovimentacaoModule,
        RendaVariavelModule,
        ConsorcioModule,
        MatTableModule,
        NgxHorizontalTimelineModule,
        MatStepperModule,
        MatSlideToggleModule,
        MatButtonToggleModule
    ],
    declarations: [
        ListaComponent,
        CadastroCompletoComponent,
        CadastroCompletoStepperComponent,
        FiltrarClienteComponent,
        CadastroRelacionamentoComponent,
        FiltrarRelacionamentoComponent,
        AlteraCarteiraComponent,
        ListaStatusOperacaoComponent,
        PosicaoConsolidadaComponent,
        CadastroAporteRetiradaComponent,
        FeedbackClienteComponent,
        ListaSaldoClienteComponent,
        CadastraSaldoClienteComponent,
        ListaClientesSemOrdemComponent,
        ListaHistoricoClienteComponent,
        CadastroHistoricoClienteComponent,
        FiltroHistoricoClienteComponent,
        UploadHistoricoClienteComponent,
        ListaIconsistenciaClienteComponent,
        FiltrarInconsistenciasOrdensComponent,
        ListaHistoricoRelacionamentoComponent,
        ClienteProdutosComponent,
        CoeComponent,
        ClienteRendaFixaComponent,
        CadastroProdutoComponent,
        ClientePrevidenciaComponent,
        ClienteFundoInvestimentoComponent,
        ClienteRendaVariavelComponent,
        CadastroCompletoPainelComponent,
        TimeLineClienteComponent,
        CadastroClienteSimplesComponent,
        ListaCustodiaRvClienteComponent,
        ListaEstrategiaCustodiaComponent,
        ListaCustodiaDerivativosComponent,
        ListaEstruturaFamiliarComponent,
        ListaCustodiaProventosComponent,
        InvestimentosComponent,
        ClienteRendaVariavelPerfilComponent,
        CadastroDadosFinanceiroComponent,
        CadastroEstruturaFamiliarComponent,
        EvolucaoPatrimonialComponent,
        HistoricoMovimentacaoComponent,
        ModalDetailNnmComponent,
        ModalRelacionamentoComponent,
        CadastroRelacionamentoClienteComponent,
        GestaoRelacionamentoClienteComponent,
        ListaOportunidadeClienteComponent,
        CadastroOportunidadeComponent,
        ListaLimitadaComponent,
        ListaTipoCustoComponent,
        CadastroTipoCustoComponent,
        ListaTipoReceitaComponent,
        CadastroTipoReceitaComponent,
        ListaTipoPatrimonioComponent,
        CadastroTipoPatrimonioComponent,
        ListaClienteCustoComponent,
        CadastroClienteCustosComponent,
        ListaClienteReceitaComponent,
        CadastroClienteReceitasComponent,
        ListaClientePatrimonioComponent,
        CadastroClientePatrimoniosComponent,
        ListaClienteProjetoComponent,
        CadastroClienteProjetosComponent,
        ListaClienteIndicacaoComponent,
        CadastroClienteIndicacaoComponent,
        ListaGestaoRelacionamentoComponent,
        FiltrarGestaoRelacionamentoComponent
    ],
    entryComponents: [
        CadastroCompletoComponent,
        FiltrarClienteComponent,
        CadastroRelacionamentoComponent,
        FiltrarRelacionamentoComponent,
        AlteraCarteiraComponent,
        CadastroAporteRetiradaComponent,
        CadastraSaldoClienteComponent,
        CadastroHistoricoClienteComponent,
        FiltroHistoricoClienteComponent,
        UploadHistoricoClienteComponent,
        FiltrarInconsistenciasOrdensComponent,
        CadastroProdutoComponent,
        TimeLineClienteComponent,
        CadastroClienteSimplesComponent,
        CadastroEstruturaFamiliarComponent,
        ModalDetailNnmComponent,
        ModalRelacionamentoComponent,
        CadastroRelacionamentoClienteComponent,
        GestaoRelacionamentoClienteComponent,
        ListaOportunidadeClienteComponent,
        CadastroOportunidadeComponent,
        ListaLimitadaComponent,
        ListaTipoCustoComponent,
        CadastroTipoCustoComponent,
        ListaTipoReceitaComponent,
        CadastroTipoReceitaComponent,
        ListaTipoPatrimonioComponent,
        CadastroTipoPatrimonioComponent,
        ListaClienteCustoComponent,
        CadastroClienteCustosComponent,
        ListaClienteReceitaComponent,
        CadastroClienteReceitasComponent,
        ListaClientePatrimonioComponent,
        CadastroClientePatrimoniosComponent,
        ListaClienteProjetoComponent,
        CadastroClienteProjetosComponent,
        ListaClienteIndicacaoComponent,
        CadastroClienteIndicacaoComponent,
        ListaGestaoRelacionamentoComponent,
        FiltrarGestaoRelacionamentoComponent
    ],
    providers: [
        AuthService,
        ClienteService,
        EnderecoService,
        EmpresaService,
        ClienteRelacionentoService,
        CarteiraService,
        StatusOperacaoService,
        ClientePerfilService,
        FeedbackService,
        SaldoClientesService,
        ClienteHistoricoService,
        EstadoCivilService,
        ClienteFundoInvestimentoService,
        ClienteCarteiraHistoricoService,
        ProfissaoService,
        SituacaoTributariaService,
        PrimeiroContatoService,
        OrigemService,
        TipoOportunidadeService,
        ClienteOportunidadeService,
        TipoCustoService,
        TipoReceitaService,
        TipoPatrimonioService,
        ClienteCustoService,
        ClienteReceitaService,
        ClientePatrimonioService,
        ClienteProjetoService,
        ClienteIndicacaoService,
        TarefasRespostaUsuarioService
    ],
    exports: [
        CadastroClienteSimplesComponent,
        FiltrarClienteComponent,
        ListaEstruturaFamiliarComponent,
        CadastroEstruturaFamiliarComponent,
        ModalDetailNnmComponent,
        GestaoRelacionamentoClienteComponent,
        CadastroOportunidadeComponent,
        ListaTipoCustoComponent,
        CadastroTipoCustoComponent,
        ListaTipoReceitaComponent,
        CadastroTipoReceitaComponent,
        ListaTipoPatrimonioComponent,
        CadastroTipoPatrimonioComponent,
        ListaClienteCustoComponent,
        CadastroClienteCustosComponent,
        ListaClienteReceitaComponent,
        CadastroClienteReceitasComponent,
        ListaClientePatrimonioComponent,
        CadastroClientePatrimoniosComponent,
        ListaClienteProjetoComponent,
        CadastroClienteProjetosComponent,
        ListaClienteIndicacaoComponent,
        CadastroClienteIndicacaoComponent
    ],
})
export class ClientesModule {}
